
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400;1,700&family=VT323&display=swap');

.App {
  display: grid;

  grid-template: calc(50vh - 2.5rem) calc(50vh - 2.5rem) 5rem / 25rem 1fr;
  grid-template-areas: "characters dice" "characters chat" "controls controls";

  @media screen and (min-width: 1000px) {
    grid-template: calc(100vh - 5rem) 5rem / 25rem 1fr 25rem;
    grid-template-areas: "characters dice chat" "characters controls controls";
  }

  &__characters {
    grid-area: characters;
  }

  &__dice {
    grid-area: dice;
    pointer-events: none;
    z-index: 2;
  }

  &__chat {
    grid-area: chat;
    z-index: 1;
  }

  &__controls {
    grid-area: controls;
    z-index: 1;
  }

  &__help {
    grid-area: dice;
  }
}

html, body {
  background-color: var(--night);
  color: var(--ghost);
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  line-height: 1.618rem;
}

@import 'sanitize.css';

@font-face {
    font-family: 'downcomeregular';
    src: url('/fonts/downcome-webfont.woff2') format('woff2'),
         url('/fonts/downcome-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

:root {
  --ghost: #fefefe;
  --stone: #aab2b3;
  --fresh: #bf0404;
  --blood: #63323b;
  --night: #0f1a1d;;
  --undeath: #529ba1;
  --grave: #44828c;

  --moss: #a6f2a9;
  --glow: #ffbc6d;
  --stone: #333333;
  --dusk: #242830;
  --night: #12171a;
  --black: #000;
  --moon: #aab2b3;
  --ghost: #fefefe;
}

@import 'components/App';
@import 'components/Dicebox';

::-webkit-scrollbar {
  width: .5rem;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: var(--stone);
  border-radius: 1rem;
}

::-webkit-scrollbar-thumb:hover {
  background-color: transparent;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}


/**
 * Extra resets
 */

// Get rid of the not allowed thing, it's silly.
input[disabled], button[disabled] {
  cursor: unset;
}



/** bad kevin */
.App__help {
  align-items: start;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  margin: 0 1rem;
}

.HelpGroup {
  padding: 0 1rem;
  background-color: var(--night);
  color: var(--moon);
  z-index: 1;
  border: 6px double var(--moon);
  box-shadow: 0 0 0 2px var(--stone), 0 0 0 2px inset var(--stone), 0 5px 20px 0 inset var(--stone);
  margin: 6px;

  &__heading {
    font-family: 'VT323';
    font-weight: 400;
    color: var(--ghost);
  }
}
